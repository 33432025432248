import React from "react";
import { Link } from "react-router-dom";
import {
  FaBriefcase,
  FaCode,
  FaPencilRuler,
  FaGraduationCap,
  FaMapMarkerAlt,
} from "react-icons/fa";
import careerBanner from "../../../assets/carrer/careerBanner.png";
import carrerPhoneBanner from "../../../assets/carrer/carrerPhoneBanner.png";
import { FaArrowRight } from "react-icons/fa6";

const jobOpenings = [
  {
    id: 1,
    title: "UI/UX Designer",
    department: "Design",
    location: "Remote",
    type: "Full-time",
    icon: <FaPencilRuler className="h-6 w-6" />,
  },
  {
    id: 2,
    title: "Web Developer",
    department: "Engineering",
    location: "New York, NY",
    type: "Full-time",
    icon: <FaCode className="h-6 w-6" />,
  },
  {
    id: 3,
    title: "Marketing Intern",
    department: "Marketing",
    location: "San Francisco, CA",
    type: "Internship",
    icon: <FaGraduationCap className="h-6 w-6" />,
  },
  {
    id: 4,
    title: "Product Manager",
    department: "Product",
    location: "London, UK",
    type: "Full-time",
    icon: <FaBriefcase className="h-6 w-6" />,
  },
];

export default function CareerPage() {
  return (
    <div className="bg-black">
      {/* Hero Section with Background */}
      <div
        className="bg-cover bg-fixed hidden lg:flex"
        style={{
          // backgroundImage: `url('https://png.pngtree.com/background/20230417/original/pngtree-robot-futuristic-sci-fi-virtual-system-background-picture-image_2445920.jpg')`,
          backgroundImage: `url('https://production-prod-trueprofile-blogassets-origin.s3.ap-southeast-1.amazonaws.com/uploads/2021/03/AdobeStock_126279480-1024x683.jpg')`,
        }}
      >
        <img src={careerBanner} alt="Career Banner" className="w-full" />
      </div>
      <div
        className="bg-cover bg-fixed lg:hidden flex"
        style={{
          // backgroundImage: `url('https://png.pngtree.com/background/20230417/original/pngtree-robot-futuristic-sci-fi-virtual-system-background-picture-image_2445920.jpg')`,
          backgroundImage: `url('https://production-prod-trueprofile-blogassets-origin.s3.ap-southeast-1.amazonaws.com/uploads/2021/03/AdobeStock_126279480-1024x683.jpg')`,

        }}
      >
        <img src={carrerPhoneBanner} alt="Career Banner" className="w-full" />
      </div>

      {/* Main Content */}
      <div className="bg-gradient-to-t from-[#26B6CE] to-black max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:px-8 relative -mt-10 ">
        <h2 className="text-3xl font-bold mb-8 text-white">Open Positions</h2>
        <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-3">
          {jobOpenings.map((job) => (
            <div
              key={job.id}
              className="bg-white rounded-lg shadow-md overflow-hidden transition-all duration-300 hover:shadow-xl"
            >
              <div className="p-6">
                <div className="flex items-center justify-between mb-4">
                  <div className="flex items-center space-x-3">
                    <div className="bg-blue-100 p-2 rounded-full">
                      {job.icon}
                    </div>
                    <h3 className="text-xl font-semibold text-gray-800">
                      {job.title}
                    </h3>
                  </div>
                  <span className="px-3 py-1 text-xs font-semibold text-[#26B6CE] bg-blue-100 rounded-full">
                    {job.type}
                  </span>
                </div>
                <div className="space-y-2">
                  <p className="flex items-center text-gray-600">
                    <FaBriefcase className="mr-2 text-gray-400" />
                    {job.department}
                  </p>
                  <p className="flex items-center text-gray-600">
                    <FaMapMarkerAlt className="mr-2 text-gray-400" />
                    {job.location}
                  </p>
                </div>
              </div>
              <div className="px-6 py-4 bg-gray-50 border-t border-gray-100">
                <Link
                  to={`/carrer/${job.id}`}
                  className="group flex items-center justify-center w-full text-[#26B6CE] font-semibold py-2 px-4 rounded-md border border-[#26B6CE] transition-all duration-300 hover:bg-[#26B6CE] hover:text-white"
                >
                  Apply Now
                  <FaArrowRight className="ml-2 transform group-hover:translate-x-1 transition-transform duration-300" />
                </Link>
              </div>
            </div>
          ))}
        </div>
        </div>
    </div>
  );
}
