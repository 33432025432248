import React, { useState } from "react";
import { motion } from "framer-motion";
import coursesBanner from "../../../assets/courseBanner.png";
import hexagonalBackground from "../../../assets/cources/hexagonalBackground.avif";
import courcesPhoneBanner from "../../../assets/cources/courcesPhoneBanner.png";
import data from "./courseData.json";
import { useNavigate } from "react-router-dom";

// Expanded dummy data for 12 courses
// const courses = [
//   {
//     title: "Abstract Design",
//     description:
//       "Learn the fundamentals of abstract design and create stunning visuals.",
//     image:
//       "https://images.pexels.com/photos/2156881/pexels-photo-2156881.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
//     participants: [
//       "https://images.pexels.com/photos/89790/beauty-woman-portrait-face-89790.jpeg?crop=faces&fit=crop&h=200&w=200&auto=compress&cs=tinysrgb",
//       "https://images.pexels.com/photos/1222271/pexels-photo-1222271.jpeg?auto=compress&cs=tinysrgb&crop=faces&fit=crop&h=200&w=200",
//       "https://images.pexels.com/photos/769772/pexels-photo-769772.jpeg?auto=compress&cs=tinysrgb&crop=faces&fit=crop&h=200&w=200",
//       "https://images.pexels.com/photos/1102341/pexels-photo-1102341.jpeg?auto=compress&cs=tinysrgb&crop=faces&fit=crop&h=200&w=200",
//     ],
//     originalPrice: 100,
//     discountedPrice: 80,
//     category: "Design",
//   },
//   {
//     title: "Web Design",
//     description:
//       "Master the art of creating beautiful and functional websites.",
//     image:
//       "https://images.pexels.com/photos/326501/pexels-photo-326501.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
//     participants: [
//       "https://images.pexels.com/photos/1758845/pexels-photo-1758845.jpeg?auto=compress&cs=tinysrgb&crop=faces&fit=crop&h=200&w=200",
//       "https://images.pexels.com/photos/1832959/pexels-photo-1832959.jpeg?auto=compress&cs=tinysrgb&crop=faces&fit=crop&h=200&w=200",
//       "https://images.pexels.com/photos/38554/girl-people-landscape-sun-38554.jpeg?auto=compress&cs=tinysrgb&crop=faces&fit=crop&h=200&w=200",
//       "https://images.pexels.com/photos/412840/pexels-photo-412840.jpeg?crop=faces&fit=crop&h=200&w=200&auto=compress&cs=tinysrgb",
//     ],
//     originalPrice: 120,
//     discountedPrice: 90,
//     category: "Web",
//   },
//   {
//     title: "Photography",
//     description:
//       "Capture stunning images and learn professional photography techniques.",
//     image:
//       "https://images.pexels.com/photos/986733/pexels-photo-986733.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
//     participants: [
//       "https://images.pexels.com/photos/91227/pexels-photo-91227.jpeg?auto=compress&cs=tinysrgb&crop=faces&fit=crop&h=200&w=200",
//       "https://images.pexels.com/photos/458766/pexels-photo-458766.jpeg?crop=faces&fit=crop&h=200&w=200&auto=compress&cs=tinysrgb",
//       "https://images.pexels.com/photos/458718/pexels-photo-458718.jpeg?crop=faces&fit=crop&h=200&w=200&auto=compress&cs=tinysrgb",
//       "https://images.pexels.com/photos/573299/pexels-photo-573299.jpeg?auto=compress&cs=tinysrgb&crop=faces&fit=crop&h=200&w=200",
//     ],
//     originalPrice: 150,
//     discountedPrice: 110,
//     category: "Photography",
//   },
//   {
//     title: "UI/UX Design",
//     description:
//       "Create intuitive and engaging user interfaces and experiences.",
//     image:
//       "https://images.pexels.com/photos/196644/pexels-photo-196644.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
//     participants: [
//       "https://images.pexels.com/photos/415829/pexels-photo-415829.jpeg?auto=compress&cs=tinysrgb&crop=faces&fit=crop&h=200&w=200",
//       "https://images.pexels.com/photos/1040881/pexels-photo-1040881.jpeg?auto=compress&cs=tinysrgb&crop=faces&fit=crop&h=200&w=200",
//       "https://images.pexels.com/photos/1542085/pexels-photo-1542085.jpeg?auto=compress&cs=tinysrgb&crop=faces&fit=crop&h=200&w=200",
//       "https://images.pexels.com/photos/1516680/pexels-photo-1516680.jpeg?auto=compress&cs=tinysrgb&crop=faces&fit=crop&h=200&w=200",
//     ],
//     originalPrice: 130,
//     discountedPrice: 100,
//     category: "Design",
//   },
//   {
//     title: "Digital Marketing",
//     description:
//       "Learn effective strategies to promote products and services online.",
//     image:
//       "https://images.pexels.com/photos/905163/pexels-photo-905163.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
//     participants: [
//       "https://images.pexels.com/photos/2379005/pexels-photo-2379005.jpeg?auto=compress&cs=tinysrgb&crop=faces&fit=crop&h=200&w=200",
//       "https://images.pexels.com/photos/2613260/pexels-photo-2613260.jpeg?auto=compress&cs=tinysrgb&crop=faces&fit=crop&h=200&w=200",
//       "https://images.pexels.com/photos/2100063/pexels-photo-2100063.jpeg?auto=compress&cs=tinysrgb&crop=faces&fit=crop&h=200&w=200",
//       "https://images.pexels.com/photos/1858175/pexels-photo-1858175.jpeg?auto=compress&cs=tinysrgb&crop=faces&fit=crop&h=200&w=200",
//     ],
//     originalPrice: 140,
//     discountedPrice: 105,
//     category: "Marketing",
//   },
//   {
//     title: "3D Modeling",
//     description: "Create stunning 3D models for games, movies, and more.",
//     image:
//       "https://images.pexels.com/photos/2156/sky-earth-space-working.jpg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
//     participants: [
//       "https://images.pexels.com/photos/614810/pexels-photo-614810.jpeg?auto=compress&cs=tinysrgb&crop=faces&fit=crop&h=200&w=200",
//       "https://images.pexels.com/photos/2379004/pexels-photo-2379004.jpeg?auto=compress&cs=tinysrgb&crop=faces&fit=crop&h=200&w=200",
//       "https://images.pexels.com/photos/1516680/pexels-photo-1516680.jpeg?auto=compress&cs=tinysrgb&crop=faces&fit=crop&h=200&w=200",
//       "https://images.pexels.com/photos/1239291/pexels-photo-1239291.jpeg?auto=compress&cs=tinysrgb&crop=faces&fit=crop&h=200&w=200",
//     ],
//     originalPrice: 160,
//     discountedPrice: 120,
//     category: "3D",
//   },
//   {
//     title: "Content Writing",
//     description: "Master the art of creating engaging and persuasive content.",
//     image:
//       "https://images.pexels.com/photos/3059747/pexels-photo-3059747.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
//     participants: [
//       "https://images.pexels.com/photos/3777943/pexels-photo-3777943.jpeg?auto=compress&cs=tinysrgb&crop=faces&fit=crop&h=200&w=200",
//       "https://images.pexels.com/photos/3777931/pexels-photo-3777931.jpeg?auto=compress&cs=tinysrgb&crop=faces&fit=crop&h=200&w=200",
//       "https://images.pexels.com/photos/3785077/pexels-photo-3785077.jpeg?auto=compress&cs=tinysrgb&crop=faces&fit=crop&h=200&w=200",
//       "https://images.pexels.com/photos/3775540/pexels-photo-3775540.jpeg?auto=compress&cs=tinysrgb&crop=faces&fit=crop&h=200&w=200",
//     ],
//     originalPrice: 90,
//     discountedPrice: 70,
//     category: "Writing",
//   },
//   {
//     title: "Data Science",
//     description: "Learn to analyze and interpret complex data sets.",
//     image:
//       "https://images.pexels.com/photos/669615/pexels-photo-669615.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
//     participants: [
//       "https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&crop=faces&fit=crop&h=200&w=200",
//       "https://images.pexels.com/photos/1043471/pexels-photo-1043471.jpeg?auto=compress&cs=tinysrgb&crop=faces&fit=crop&h=200&w=200",
//       "https://images.pexels.com/photos/774909/pexels-photo-774909.jpeg?auto=compress&cs=tinysrgb&crop=faces&fit=crop&h=200&w=200",
//       "https://images.pexels.com/photos/1516680/pexels-photo-1516680.jpeg?auto=compress&cs=tinysrgb&crop=faces&fit=crop&h=200&w=200",
//     ],
//     originalPrice: 180,
//     discountedPrice: 135,
//     category: "Data",
//   },
//   {
//     title: "Mobile App Development",
//     description: "Build powerful and intuitive mobile applications.",
//     image:
//       "https://images.pexels.com/photos/1092644/pexels-photo-1092644.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
//     participants: [
//       "https://images.pexels.com/photos/1516680/pexels-photo-1516680.jpeg?auto=compress&cs=tinysrgb&crop=faces&fit=crop&h=200&w=200",
//       "https://images.pexels.com/photos/1239291/pexels-photo-1239291.jpeg?auto=compress&cs=tinysrgb&crop=faces&fit=crop&h=200&w=200",
//       "https://images.pexels.com/photos/2379005/pexels-photo-2379005.jpeg?auto=compress&cs=tinysrgb&crop=faces&fit=crop&h=200&w=200",
//       "https://images.pexels.com/photos/2613260/pexels-photo-2613260.jpeg?auto=compress&cs=tinysrgb&crop=faces&fit=crop&h=200&w=200",
//     ],
//     originalPrice: 170,
//     discountedPrice: 130,
//     category: "Mobile",
//   },
//   {
//     title: "Graphic Design",
//     description:
//       "Create visually stunning designs for print and digital media.",
//     image:
//       "https://images.pexels.com/photos/196644/pexels-photo-196644.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
//     participants: [
//       "https://images.pexels.com/photos/415829/pexels-photo-415829.jpeg?auto=compress&cs=tinysrgb&crop=faces&fit=crop&h=200&w=200",
//       "https://images.pexels.com/photos/1040881/pexels-photo-1040881.jpeg?auto=compress&cs=tinysrgb&crop=faces&fit=crop&h=200&w=200",
//       "https://images.pexels.com/photos/1542085/pexels-photo-1542085.jpeg?auto=compress&cs=tinysrgb&crop=faces&fit=crop&h=200&w=200",
//       "https://images.pexels.com/photos/1516680/pexels-photo-1516680.jpeg?auto=compress&cs=tinysrgb&crop=faces&fit=crop&h=200&w=200",
//     ],
//     originalPrice: 130,
//     discountedPrice: 100,
//     category: "Design",
//   },
//   {
//     title: "Video Editing",
//     description: "Learn professional video editing techniques and tools.",
//     image:
//       "https://images.pexels.com/photos/2510428/pexels-photo-2510428.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
//     participants: [
//       "https://images.pexels.com/photos/91227/pexels-photo-91227.jpeg?auto=compress&cs=tinysrgb&crop=faces&fit=crop&h=200&w=200",
//       "https://images.pexels.com/photos/458766/pexels-photo-458766.jpeg?crop=faces&fit=crop&h=200&w=200&auto=compress&cs=tinysrgb",
//       "https://images.pexels.com/photos/458718/pexels-photo-458718.jpeg?crop=faces&fit=crop&h=200&w=200&auto=compress&cs=tinysrgb",
//       "https://images.pexels.com/photos/573299/pexels-photo-573299.jpeg?auto=compress&cs=tinysrgb&crop=faces&fit=crop&h=200&w=200",
//     ],
//     originalPrice: 150,
//     discountedPrice: 115,
//     category: "Video",
//   },
//   {
//     title: "Machine Learning",
//     description: "Dive into the world of AI and machine learning algorithms.",
//     image:
//       "https://images.pexels.com/photos/2599244/pexels-photo-2599244.jpeg?auto=compress&cs=tinysrg&dpr=2&h=750&w=1260",
//     participants: [
//       "https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&crop=faces&fit=crop&h=200&w=200",
//       "https://images.pexels.com/photos/1043471/pexels-photo-1043471.jpeg?auto=compress&cs=tinysrgb&crop=faces&fit=crop&h=200&w=200",
//       "https://images.pexels.com/photos/774909/pexels-photo-774909.jpeg?auto=compress&cs=tinysrgb&crop=faces&fit=crop&h=200&w=200",
//       "https://images.pexels.com/photos/1516680/pexels-photo-1516680.jpeg?auto=compress&cs=tinysrgb&crop=faces&fit=crop&h=200&w=200",
//     ],
//     originalPrice: 200,
//     discountedPrice: 150,
//     category: "AI",
//   },
// ];

export default function Component() {
  const [hoveredCourseIndex, setHoveredCourseIndex] = useState(null);
  const navigate = useNavigate();
  const participants = [
    "https://images.pexels.com/photos/89790/beauty-woman-portrait-face-89790.jpeg?crop=faces&fit=crop&h=200&w=200&auto=compress&cs=tinysrgb",
    "https://images.pexels.com/photos/1222271/pexels-photo-1222271.jpeg?auto=compress&cs=tinysrgb&crop=faces&fit=crop&h=200&w=200",
    "https://images.pexels.com/photos/769772/pexels-photo-769772.jpeg?auto=compress&cs=tinysrgb&crop=faces&fit=crop&h=200&w=200",
    "https://images.pexels.com/photos/1102341/pexels-photo-1102341.jpeg?auto=compress&cs=tinysrgb&crop=faces&fit=crop&h=200&w=200",
  ];

  return (
    <div>
      <div
        className=" bg-cover bg-fixed lg:flex hidden"
        style={{
          // backgroundImage: `url(${hexagonalBackground})`,
          backgroundImage: `url(${"https://t4.ftcdn.net/jpg/04/45/70/71/360_F_445707107_elptpcI7pUDPa9kMdnX9e3506QdHfo7r.jpg"})`,
        }}
      >
        <img src={coursesBanner} alt="" className="w-full" />
      </div>
      <div
        className=" bg-cover bg-fixed flex lg:hidden"
        style={{
          backgroundImage: `url(${hexagonalBackground})`,
        }}
      >
        <img src={courcesPhoneBanner} alt="" className="w-full" />
      </div>

      <div className="min-h-screen bg-gradient-to-t from-[#25B7CE] to-[#1A273C] py-12 px-4 sm:px-6 lg:px-8">
        <div className="max-w-7xl mx-auto">
          <div className="grid grid-cols-1 gap-y-10 gap-x-6 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 xl:gap-x-8">
            {data?.courses?.map((course, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.3, delay: index * 0.1 }}
                className="cursor-pointer lg:contents hidden"
                onClick={() => {
                  navigate(`/courses/${course?.id}`);
                }}
              >
                <div
                  className="bg-white rounded-xl shadow-lg overflow-hidden h-full transition-all duration-300 hover:shadow-2xl hover:scale-105 transform"
                  onMouseEnter={() => setHoveredCourseIndex(index)}
                  onMouseLeave={() => setHoveredCourseIndex(null)}
                >
                  <div className="relative">
                    <img
                      src={course.image}
                      alt={course.title}
                      className="w-full h-48 object-cover"
                    />
                    <div className="absolute top-2 left-2 bg-white px-3 py-1 rounded-full text-sm font-semibold text-gray-700 shadow-md">
                      {course.category}
                    </div>
                  </div>
                  <div className="p-6">
                    <h2 className="text-xl font-bold text-gray-900 mb-2">
                      {course.title}
                    </h2>
                    <p className="text-sm text-gray-600 mb-4">
                      {course.description}
                    </p>
                    <div className="flex items-center justify-between">
                      <div className="flex -space-x-2 overflow-hidden">
                        {participants.map((participant, pIndex) => (
                          <img
                            key={pIndex}
                            className="inline-block h-8 w-8 rounded-full ring-2 ring-white"
                            src={participant}
                            alt={`Participant ${pIndex + 1}`}
                          />
                        ))}
                      </div>
                      <motion.div
                        initial={{ opacity: 0, x: 10 }}
                        animate={{
                          opacity: hoveredCourseIndex === index ? 1 : 0,
                          x: hoveredCourseIndex === index ? 0 : 10,
                        }}
                        transition={{ duration: 0.2 }}
                        className="text-right"
                      >
                        <p className="text-sm text-gray-500 line-through">
                          ${course.fee}
                        </p>
                        <p className="text-lg font-semibold text-green-600">
                          ${course.fee}
                        </p>
                      </motion.div>
                    </div>
                  </div>
                  {/* <div className="px-6 pb-4">
                <motion.button
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  className="w-full bg-blue-600 text-white font-semibold py-2 px-4 rounded-lg hover:bg-blue-700 transition-colors duration-300"
                >
                  View
                </motion.button>
              </div> */}
                </div>
              </motion.div>
            ))}
            {data?.courses?.map((course, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.3, delay: index * 0.1 }}
                className="cursor-pointer lg:hidden contents"
              >
                <div
                  className="bg-white rounded-xl shadow-lg overflow-hidden h-full transition-all duration-300 lg:hover:shadow-2xl lg:hover:scale-105 transform"
                  onMouseEnter={() => setHoveredCourseIndex(index)}
                  onMouseLeave={() => setHoveredCourseIndex(null)}
                >
                  <div className="relative">
                    <img
                      src={course.image}
                      alt={course.title}
                      className="w-full h-48 object-cover"
                    />
                    <div className="absolute top-2 left-2 bg-white px-3 py-1 rounded-full text-sm font-semibold text-gray-700 shadow-md">
                      {course.category}
                    </div>
                  </div>
                  <div className="p-6">
                    <h2 className="text-xl font-bold text-gray-900 mb-2">
                      {course.title}
                    </h2>
                    <p className="text-sm text-gray-600 mb-4">
                      {course.description}
                    </p>
                    <div className="flex items-center justify-between">
                      <div className="flex -space-x-2 overflow-hidden">
                        {participants.map((participant, pIndex) => (
                          <img
                            key={pIndex}
                            className="inline-block h-8 w-8 rounded-full ring-2 ring-white"
                            src={participant}
                            alt={`Participant ${pIndex + 1}`}
                          />
                        ))}
                      </div>
                      <div className="text-right">
                        <p className="text-sm text-gray-500 line-through">
                          ${course.fee}
                        </p>
                        <p className="text-lg font-semibold text-green-600">
                          ${course.fee}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </motion.div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
