import React, { useState } from 'react'
import { FaBriefcase, FaMapMarkerAlt, FaClock } from 'react-icons/fa'
import styles from './Carrer.module.css'
import jdBanner from '../../../assets/carrer/jdBanner.png';
import jdBannerPhone from '../../../assets/carrer/jdBannerPhone.png';

// In a real application, you would fetch this data from an API
const jobDetails = {
    id: 1,
    title: "Senior Web Developer",
    department: "Engineering",
    location: "Remote",
    type: "Full-time",
    description: "We are seeking an experienced Senior Web Developer to join our dynamic team. The ideal candidate will have a strong background in front-end and back-end technologies, with a passion for creating efficient, scalable, and innovative web solutions.",
    responsibilities: [
        "Develop and maintain web applications using modern JavaScript frameworks",
        "Collaborate with cross-functional teams to define, design, and ship new features",
        "Ensure the technical feasibility of UI/UX designs",
        "Optimize applications for maximum speed and scalability",
        "Participate in code reviews and mentor junior developers"
    ],
    requirements: [
        "5+ years of experience in web development",
        "Strong proficiency in JavaScript, including DOM manipulation and the JavaScript object model",
        "Thorough understanding of React.js and its core principles",
        "Experience with popular React.js workflows (such as Flux or Redux)",
        "Familiarity with RESTful APIs and modern authorization mechanisms",
        "Knowledge of modern authorization mechanisms, such as JSON Web Token",
        "Familiarity with modern front-end build pipelines and tools",
        "Experience with common front-end development tools such as Babel, Webpack, NPM, etc.",
        "Ability to understand business requirements and translate them into technical requirements"
    ]
}

export default function JobDetails() {
    const [formData, setFormData] = useState({
        fullName: '',
        email: '',
        phone: '',
        resume: null,
        coverLetter: ''
    })

    const handleInputChange = (e) => {
        const { name, value } = e.target
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }))
    }

    const handleFileChange = (e) => {
        if (e.target.files) {
            setFormData(prevState => ({
                ...prevState,
                resume: e.target.files[0]
            }))
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        // Here you would typically send the form data to your server
        // console.log('Form submitted:', formData)
        // alert('Application submitted successfully!')
    }

    return (
        <div className="bg-gradient-to-b from-black to-[#26B6CE] via-black">
            <div
                className="bg-cover bg-fixed lg:flex hidden"
                style={{
                    backgroundImage: `url('https://www.acewebacademy.com/wp-content/uploads/2023/02/Prerequisites-to-becoming-a-full-stack-developer-min.jpg')`,
                }}
            >
                <img src={jdBanner} alt="About Us Banner" className="w-full" />
            </div>
            <div
                className="bg-cover bg-fixed flex lg:hidden"
                style={{
                    backgroundImage: `url('https://www.acewebacademy.com/wp-content/uploads/2023/02/Prerequisites-to-becoming-a-full-stack-developer-min.jpg')`,
                }}
            >
                <img
                    src={jdBannerPhone}
                    alt="About Us Banner"
                    className="w-full"
                />
            </div>
            <div className="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-20 relative z-50 ">
                <div className="bg- rounded-lg shadow-md overflow-hidden bg-white">
                    <div className="p-8">
                        <div className="prose max-w-none mb-8">
                            <h3 className="text-2xl font-semibold mb-2">Job Description</h3>
                            <p>{jobDetails.description}</p>
                            <h3 className="text-xl font-semibold mt-4 mb-2">Responsibilities</h3>
                            <ul>
                                {jobDetails.responsibilities.map((responsibility, index) => (
                                    <li key={index}>{responsibility}</li>
                                ))}
                            </ul>
                            <h3 className="text-xl font-semibold mt-4 mb-2">Requirements</h3>
                            <ul>
                                {jobDetails.requirements.map((requirement, index) => (
                                    <li key={index}>{requirement}</li>
                                ))}
                            </ul>
                        </div>
                        <div>
                            <h3 className="text-2xl font-bold mb-4">Apply for this position</h3>
                            <form onSubmit={handleSubmit} className="space-y-4">
                                <div>
                                    <input
                                        className={styles['input']}
                                        placeholder='Full name'
                                        type="text"
                                        id="fullName"
                                        name="fullName"
                                        required
                                        value={formData.fullName}
                                        onChange={handleInputChange}
                                    />
                                </div>
                                <div>
                                    <input
                                        className={styles['input']}
                                        type="email"
                                        id="email"
                                        name="email"
                                        required
                                        placeholder='Email'
                                        value={formData.email}
                                        onChange={handleInputChange}
                                    />
                                </div>
                                <div>
                                    <input
                                        type="tel"
                                        id="phone"
                                        name="phone"
                                        required
                                        placeholder='Phone'
                                        value={formData.phone}
                                        onChange={handleInputChange}
                                        className={styles['input']}
                                    />
                                </div>
                                <div>
                                    <label htmlFor="resume" className="block text-sm font-medium text-gray-700">Resume</label>
                                    <input
                                        type="file"
                                        id="resume"
                                        name="resume"
                                        required
                                        accept=".pdf,.doc,.docx"
                                        className="mt-1 block w-full text-sm text-gray-500
                      file:mr-4 file:py-2 file:px-4
                      file:rounded-md file:border-0
                      file:text-sm file:font-semibold
                      file:bg-blue-50 file:text-qlithSky
                      hover:file:bg-blue-100"
                                        onChange={handleFileChange}
                                    />
                                </div>
                                <div>
                                    <textarea
                                        id="coverLetter"
                                        name="coverLetter"
                                        rows={4}
                                        className={styles['input']}
                                        placeholder='Cover Letter'
                                        value={formData.coverLetter}
                                        onChange={handleInputChange}
                                    ></textarea>
                                </div>
                                <div>
                                    <button
                                        type="submit"
                                        className={styles['btn']}
                                    >
                                        Submit Application
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}