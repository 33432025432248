import React, { useState } from 'react'
import { FaXTwitter } from "react-icons/fa6";
import { FiFacebook } from "react-icons/fi";
import { LuLinkedin } from "react-icons/lu";
import { IoLogoInstagram } from "react-icons/io5";
import { Link } from 'react-router-dom';
import styles from './UserFooter.module.css';
import { LuYoutube } from "react-icons/lu";
import axios from 'axios';
import { toast } from 'react-toastify';
import Spinner from '../library/Spinner';

function UserFooter() {
    const baseUrl = process.env.REACT_APP_BASE_URL;
    const [loading, setLoading] = useState(false)
    const [email, setEmail] = useState("")


    const handelSubscribe = async () => {
        if (!email) {
            toast.warning("Please enter email")
            return;
        }
        if(!email.includes('@')){
            toast.warning("Please enter a valid email")
            return;
        }
        try {
            setLoading(true)
            const { data } = await axios.post(`${baseUrl}/contact/get-update`, { email })
            if (data?.status) {
                toast.success(data?.message)
                setEmail("")
            } else {
                toast.error(data?.message)
            }
        } catch (error) {
            toast.error(error)
        } finally {
            setLoading(false)
        }

    }
    return (
        <div className={styles['footer-main']} >
            <div className={styles['footer-flex']} >
                <div  >
                    <h2 className={styles['heading']} >Qlith Innovation Pvt Ltd</h2>
                    <p>Receive Updates on New Arrivals and Special Promotions!</p>
                    <div className='my-3 flex' >
                        <input className={styles['mail-input']} type="email" placeholder='Your email here' value={email} onChange={(e) => setEmail(e.target.value)} />
                        <div className={styles['submit']} onClick={handelSubscribe} >{loading ? <Spinner /> : 'Submit'}</div>
                    </div>
                    <div className={styles['social-media']} >
                        {/* <button className='bg-white text-black' ><FaXTwitter /></button> */}
                        <Link className='bg-[#0A66C2]' to="https://www.linkedin.com/company/qlith" target='_blank' ><LuLinkedin /></Link>
                        <Link className='bg-[#1877F2]' to="https://www.facebook.com/profile.php?id=61566921147985" target='_blank' ><FiFacebook /></Link>
                        <Link className={styles['insta-bg']} to="https://www.instagram.com/qlithinnovation/profilecard/?igsh=MTJlMjlnaDFkbG9hdA==" target='_blank' ><IoLogoInstagram /></Link>
                        <Link className='bg-red-700' to="https://youtube.com/@qlithinnovation?si=2gJclNGxK4Mzbg9M" target='_blank' > <LuYoutube /> </Link>
                    </div>
                </div>
                <div className={styles['links']} >
                    <div  >
                        <h3>Services</h3>
                        <Link>Web Development</Link>
                        <Link>App Development</Link>
                        <Link>UI/ UX Design</Link>
                    </div>
                    <div  >
                        <h3>Courses</h3>
                        <Link>MERN Stack</Link>
                        <Link>DSA</Link>
                        <Link>HTML, CSS, JS</Link>
                        <Link>UI/ UX Design</Link>
                    </div>
                    <div  >
                        <h3>Pages</h3>
                        <Link>About us</Link>
                        <Link>Blog</Link>
                        <Link>Services</Link>
                        <Link>Cources</Link>
                    </div>

                </div>
            </div>
            <div className={styles['social-media-phone']} >
                <Link className='bg-[#0A66C2]' to="https://www.linkedin.com/company/qlith" target='_blank' ><LuLinkedin /></Link>
                <Link className='bg-[#1877F2]' to="https://www.facebook.com/profile.php?id=61566921147985" target='_blank' ><FiFacebook /></Link>
                <Link className={styles['insta-bg']} to="https://www.instagram.com/qlithinnovation/profilecard/?igsh=MTJlMjlnaDFkbG9hdA==" target='_blank' ><IoLogoInstagram /></Link>
                <Link className='bg-red-700' to="https://youtube.com/@qlithinnovation?si=2gJclNGxK4Mzbg9M" target='_blank' > <LuYoutube /> </Link>
            </div>
            <div className={styles['copy-right']} ><p>© {new Date().getFullYear()} Qlith Innovation Pvt Ltd. All rights reserved.</p></div>
        </div>
    )
}

export default UserFooter