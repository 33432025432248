import React from 'react'
// import Banner from "../../../assets/home/Banner.png";
import Banner from "../../../assets/home/Banner.jpg";
import styles from "../../../screens/users/home/Home.module.css";
import { FaArrowRightLong } from "react-icons/fa6";

const HomeBanner = () => {
  return (
    <div
        className={styles["banner"]}
        style={{ backgroundImage: `url(${Banner})` }}
      >
        <div>
          <h1 className={styles["heading"]}>
            Transforming <span className="text-qlithSky">Business</span> <br />{" "}
            and Careers
          </h1>
          <h1 className={`${styles["text-outline"]}`}>THE PATH TO SUCCESS</h1>
          <h1 className={`${styles["text-outline-sm"]}`}>
            THE PATH TO SUCCESS
          </h1>
          <div className={styles["banner-btns"]}>
            <button className={styles["banner-btn"]}>
              Getting started <FaArrowRightLong />{" "}
            </button>
            <button className={styles["banner-btn"]}>
              Explore our services <FaArrowRightLong />
            </button>
          </div>
        </div>
      </div>
  )
}

export default HomeBanner;