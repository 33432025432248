import React from 'react'
import building from '../../assets/home/building.jpg'
import { motion } from "framer-motion"


export default function Building({ onclick }) {
    return (
        <div className='fixed bg-[#000000ad] botton-0 top-0 h-full z-50 flex justify-center items-center' >
            <motion.div
                initial={{ scale: 0.2 }}
                whileInView={{ scale: 1 }}
                transition={{ duration: 0.9 }}
                className='border flex justify-center flex-col items-center w-[40%] p-10 gap-2 bg-white' >
                <img src={building} alt="" />
                <h2 className='text-qlithNavy font-semibold text-xl' >"Under Construction <br /> Great Things Take Time."</h2>
                <p>
                    Contact at <a href="https://mail.google.com/mail/?view=cm&fs=1&to=official@qlith.com" target="_blank" className="text-qlithSky underline">official@qlith.com</a>
                </p>

                <button className='bg-qlithSky border border-qlithSky text-white w-full p-2 hover:bg-white hover:text-qlithSky hover:font-semibold transition-all duration-700' onClick={onclick} >OK</button>
            </motion.div>
        </div>
    )
}
