import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { useLocation, useParams } from 'react-router-dom';
import data from './courseData.json'

// Color scheme
const colors = {
  primary: 'bg-blue-600',
  secondary: 'bg-purple-600',
  accent: 'bg-yellow-500',
  text: 'text-gray-800',
  background: 'bg-gray-50',
};

const CourseDetail= () => {
  const [openFaqIndex, setOpenFaqIndex] = useState(null);
  const id=useParams()?.id
  // console.log(data?.courses)
  const filterData = data?.courses?.filter((ele) => ele?.id === Number(id));
  console.log(filterData);
  

  const courseFeatures = [
    { icon: "🕒", text: "4-month intensive program" },
    { icon: "💻", text: "Full MERN stack coverage" },
    { icon: "👥", text: "One-on-one mentorship" },
    { icon: "🚀", text: "Live project experience" },
    { icon: "📄", text: "Resume building assistance" },
    { icon: "🐙", text: "Git and GitHub mastery" },
  ];

  const curriculum = [
    { 
      title: "HTML, CSS, and JavaScript Fundamentals", 
      duration: "2 weeks",
      topics: [
        "HTML5 structure and semantics",
        "CSS3 styling and layouts",
        "JavaScript basics and DOM manipulation",
        "Responsive design principles"
      ]
    },
    { 
      title: "Advanced JavaScript and ES6+", 
      duration: "1 week",
      topics: [
        "ES6+ features and syntax",
        "Asynchronous JavaScript (Promises, Async/Await)",
        "Functional programming concepts",
        "Object-oriented JavaScript"
      ]
    },
    { 
      title: "React.js and State Management", 
      duration: "3 weeks",
      topics: [
        "React fundamentals and components",
        "Hooks and state management",
        "Redux for complex state management",
        "React Router for navigation"
      ]
    },
    { 
      title: "Node.js and Express.js", 
      duration: "2 weeks",
      topics: [
        "Node.js fundamentals",
        "Express.js framework",
        "RESTful API design",
        "Middleware and error handling"
      ]
    },
    { 
      title: "MongoDB and Mongoose", 
      duration: "2 weeks",
      topics: [
        "MongoDB basics and CRUD operations",
        "Mongoose ODM",
        "Data modeling and schema design",
        "Indexing and optimization"
      ]
    },
    { 
      title: "RESTful API Development", 
      duration: "1 week",
      topics: [
        "API design best practices",
        "Authentication and authorization",
        "API testing and documentation",
        "Rate limiting and security"
      ]
    },
    { 
      title: "Authentication and Authorization", 
      duration: "1 week",
      topics: [
        "JWT-based authentication",
        "OAuth 2.0 and social login",
        "Role-based access control",
        "Security best practices"
      ]
    },
    { 
      title: "Frontend-Backend Integration", 
      duration: "2 weeks",
      topics: [
        "Connecting React frontend to Node.js backend",
        "Handling CORS and security",
        "State management with backend integration",
        "Real-time updates with WebSockets"
      ]
    },
    { 
      title: "Deployment and DevOps Basics", 
      duration: "1 week",
      topics: [
        "Deployment to cloud platforms (Heroku, AWS)",
        "Continuous Integration/Continuous Deployment (CI/CD)",
        "Docker basics for containerization",
        "Monitoring and logging"
      ]
    },
    { 
      title: "Portfolio and Resume Building", 
      duration: "1 week",
      topics: [
        "Creating a compelling developer portfolio",
        "Resume writing for tech jobs",
        "GitHub profile optimization",
        "Mock interviews and job search strategies"
      ]
    },
  ];

  const learningOutcomes = [
    "Build full-stack web applications using the MERN stack",
    "Design and implement RESTful APIs",
    "Develop responsive and interactive user interfaces with React",
    "Work with databases and perform CRUD operations using MongoDB",
    "Implement user authentication and authorization",
    "Deploy web applications to cloud platforms",
    "Collaborate on projects using Git and GitHub",
    "Create a professional portfolio showcasing your projects",
  ];

  const instructors = [
    {
      name: "Alex Johnson",
      role: "Lead Instructor",
      bio: "10+ years of experience in full-stack development. Former senior engineer at Tech Giant Corp.",
      image: "https://via.placeholder.com/100"
    },
    {
      name: "Sarah Lee",
      role: "React Specialist",
      bio: "React core team contributor. Author of 'Mastering React' and popular open-source libraries.",
      image: "https://via.placeholder.com/100"
    },
    {
      name: "Michael Chen",
      role: "Backend Expert",
      bio: "Node.js and MongoDB guru. 8 years of experience in scalable backend architectures.",
      image: "https://via.placeholder.com/100"
    }
  ];

  const testimonials = [
    {
      name: "Emily R.",
      role: "Junior Developer",
      comment: "This course transformed my career. I went from a complete beginner to landing my first dev job in just 6 months!",
      image: "https://via.placeholder.com/60"
    },
    {
      name: "David M.",
      role: "Freelance Web Developer",
      comment: "The practical projects and mentorship were invaluable. I now have a portfolio that truly impresses clients.",
      image: "https://via.placeholder.com/60"
    },
    {
      name: "Sophia L.",
      role: "Startup Founder",
      comment: "As a non-technical founder, this course gave me the skills to build my startup's MVP. Highly recommended!",
      image: "https://via.placeholder.com/60"
    }
  ];

  const faqItems = [
    {
      question: "Is this course suitable for beginners?",
      answer: "Yes, this course is designed for both beginners and intermediate developers looking to master the MERN stack. We start with the fundamentals and progressively move to more advanced topics."
    },
    {
      question: "What are the prerequisites for this course?",
      answer: "Basic understanding of HTML, CSS, and JavaScript is recommended. However, we'll cover these topics in the first few weeks to ensure everyone is on the same page."
    },
    {
      question: "Will I receive a certificate upon completion?",
      answer: "Yes, you'll receive a certificate of completion after successfully finishing the course and the group project. This certificate can be added to your LinkedIn profile and resume."
    },
    {
      question: "How much time should I dedicate per week?",
      answer: "We recommend dedicating at least 20-25 hours per week to get the most out of this intensive program. This includes video lectures, hands-on coding, and project work."
    },
    {
      question: "Is job assistance provided?",
      answer: "While we don't guarantee job placement, we provide comprehensive career support. This includes resume building, portfolio development, interview preparation, and access to our network of hiring partners."
    },
    {
      question: "Can I access the course materials after the program ends?",
      answer: "Yes, you'll have lifetime access to all course materials, including video lectures, code samples, and projects. You can revisit the content anytime to refresh your skills."
    },
    {
      question: "Are there any live sessions or is it all pre-recorded?",
      answer: "We offer a mix of pre-recorded lectures and live coding sessions. There are weekly live Q&A sessions with instructors, and the one-on-one mentorship sessions are also conducted live."
    },
    {
      question: "What kind of projects will I build during the course?",
      answer: "You'll build several projects throughout the course, including a task management app, a social media platform, and an e-commerce website. The final group project will be a full-fledged web application that showcases all the skills you've learned."
    }
  ];

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
      },
    },
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        type: 'spring',
        stiffness: 100,
      },
    },
  };

  const learningPoints = [
    { icon: '💻', text: 'Full-stack JavaScript development' },
    { icon: '🔗', text: 'Building RESTful APIs with Node.js and Express' },
    { icon: '⚛️', text: 'Frontend development with React and Redux' },
    { icon: '🗄️', text: 'Database design and management with MongoDB' },
    { icon: '🔒', text: 'Authentication and security best practices' },
    { icon: '🚀', text: 'Deployment and DevOps basics' },
  ];

  const courseHighlights = [
    { icon: '🎥', text: 'Live coding sessions with industry experts' },
    { icon: '👨‍🏫', text: 'One-on-one mentorship throughout the course' },
    { icon: '🏗️', text: 'Real-world group projects' },
    { icon: '💼', text: 'Job-ready portfolio building' },
    { icon: '🎯', text: 'Interview preparation and career guidance' },
    { icon: '🔑', text: 'Lifetime access to course materials' },
  ];

  return (
    <div className={`min-h-screen ${colors.background} ${colors.text}`}>
      {/* Hero Section */}
      <section className={`${colors.primary} text-white py-20`}>
        <div className="container mx-auto text-center px-4">
          <h1 className="text-4xl font-bold mb-4">Master the MERN Stack</h1>
          <p className="text-xl mb-8">Become a Full-Stack Developer in 4 Months</p>
          <motion.button
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            className={`${colors.accent} text-gray-900 px-8 py-3 rounded-full text-lg font-semibold hover:brightness-110 transition duration-300`}
          >
            Enroll Now for ₹9,999
          </motion.button>
        </div>
      </section>

      {/* Course Overview */}
      <section className="py-16">
        <div className="container mx-auto px-4">
          <h2 className="text-3xl font-bold mb-8 text-center">Course Overview</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {courseFeatures.map((feature, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: index * 0.1 }}
                className="bg-white rounded-lg shadow-md p-6"
              >
                <div className="flex items-center">
                  <span className="text-3xl mr-4">{feature.icon}</span>
                  <span className="text-lg font-semibold">{feature.text}</span>
                </div>
              </motion.div>
            ))}
          </div>
        </div>
      </section>

      {/* Course Description */}
      <section className="bg-gradient-to-br from-purple-700 to-indigo-800 text-white py-20">
        <div className="container mx-auto px-4">
          <motion.div
            className="max-w-4xl mx-auto"
            variants={containerVariants}
            initial="hidden"
            animate="visible"
          >
            <motion.h2 
              className="text-4xl font-bold mb-8 text-center"
              variants={itemVariants}
            >
              Why Choose Our MERN Stack Course?
            </motion.h2>
            <motion.p 
              className="text-xl mb-12 text-center"
              variants={itemVariants}
            >
              Our comprehensive 4-month MERN stack course is designed to take you from a beginner to a job-ready full-stack developer. 
              With a perfect blend of theory and practical projects, you'll gain hands-on experience in building modern web applications 
              using MongoDB, Express.js, React, and Node.js.
            </motion.p>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-12">
              <motion.div variants={itemVariants}>
                <h3 className="text-2xl font-semibold mb-6 flex items-center">
                  <span className="text-yellow-300 mr-3 text-3xl">📚</span>
                  What You'll Learn
                </h3>
                <ul className="space-y-4">
                  {learningPoints.map((point, index) => (
                    <motion.li 
                      key={index}
                      className="flex items-start"
                      variants={itemVariants}
                    >
                      <span className="text-2xl mr-4">{point.icon}</span>
                      <span>{point.text}</span>
                    </motion.li>
                  ))}
                </ul>
              </motion.div>
              <motion.div variants={itemVariants}>
                <h3 className="text-2xl font-semibold mb-6 flex items-center">
                  <span className="text-yellow-300 mr-3 text-3xl">🌟</span>
                  Course Highlights
                </h3>
                <ul className="space-y-4">
                  {courseHighlights.map((highlight, index) => (
                    <motion.li 
                      key={index}
                      className="flex items-start"
                      variants={itemVariants}
                    >
                      <span className="text-2xl mr-4">{highlight.icon}</span>
                      <span>{highlight.text}</span>
                    </motion.li>
                  ))}
                </ul>
              </motion.div>
            </div>
            <motion.div 
              className="mt-12 text-center"
              variants={itemVariants}
            >
              <motion.button
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                className="bg-yellow-400 text-gray-900 px-8 py-3 rounded-full text-lg font-semibold hover:bg-yellow-300 transition duration-300"
              >
                Start Your MERN Journey Today
              </motion.button>
            </motion.div>
          </motion.div>
        </div>
      </section>

      {/* Curriculum */}
      <section className="py-16">
        <div className="container mx-auto px-4">
          <h2 className="text-3xl font-bold mb-8 text-center">Comprehensive Curriculum</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            {curriculum.map((item, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: index * 0.1 }}
                className="bg-white rounded-lg shadow-md p-6"
              >
                <h3 className="text-xl font-semibold mb-2">{item.title}</h3>
                <p className="text-gray-600 mb-4">{item.duration}</p>
                <ul className="list-disc list-inside">
                  {item.topics.map((topic, topicIndex) => (
                    <li key={topicIndex}>{topic}</li>
                  ))}
                </ul>
              </motion.div>
            ))}
          </div>
        </div>
      </section>

      {/* Learning Outcomes */}
      <section className={`${colors.accent} py-16`}>
        <div className="container mx-auto px-4">
          <h2 className="text-3xl font-bold mb-8 text-center text-gray-900">Learning Outcomes</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            {learningOutcomes.map((outcome, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, scale: 0.9 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ delay: index * 0.1 }}
                className="bg-white rounded-lg shadow-md p-6"
              >
                <h3 className="text-lg font-semibold mb-2 flex items-center">
                  <span className="text-2xl mr-2">🎯</span>
                  <span>Outcome {index + 1}</span>
                </h3>
                <p>{outcome}</p>
              </motion.div>
            ))}
          </div>
        </div>
      </section>

      {/* Technologies Covered */}
      <section className="py-16">
        <div className="container mx-auto px-4">
          <h2 className="text-3xl font-bold mb-8 text-center">Technologies You'll Master</h2>
          <div className="flex flex-wrap justify-center gap-4">
            {['MongoDB', 'Express.js', 'React', 'Node.js', 'Tailwind CSS', 'Bootstrap', 'HTML', 'CSS', 'JavaScript', 'Git', 'GitHub'].map((tech, index) => (
              <motion.span
                key={index}
                initial={{ opacity: 0, scale: 0.8 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ delay: index * 0.1 }}
                className="bg-gray-200 text-gray-800 rounded-full px-4 py-2 text-lg"
              >
                {tech}
              </motion.span>
            ))}
          </div>
        </div>
      </section>

      {/* Course Highlights */}
      <section className={`${colors.primary} text-white py-16`}>
        <div className="container mx-auto px-4">
          <h2 className="text-3xl font-bold mb-8 text-center">Course Highlights</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {[
              { icon: "👥", title: "Group Projects", description: "Collaborate with peers on real-world projects to build your portfolio and enhance teamwork skills." },
              { icon: "💼", title: "Live Project Experience", description: "Work on a live project with real clients to gain hands-on industry experience and build your network." },
              { icon: "📚", title: "Comprehensive Learning Materials", description: "Access a wealth of video lectures, coding exercises, quizzes, and supplementary resources to support your learning journey." },
              { icon: "👨‍🏫", title: "One-on-One Mentorship", description: "Receive personalized guidance and support from experienced mentors throughout the course." },
              { icon: "🏆", title: "Industry-Recognized Certificate", description: "Earn a certificate of completion that showcases your newly acquired skills to potential employers." },
              { icon: "🚀", title: "Career Support", description: "Benefit from resume reviews, mock interviews, and job placement assistance to kickstart your tech career." },
            ].map((highlight, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: index * 0.1 }}
                className="bg-white text-gray-800 rounded-lg shadow-md p-6"
              >
                <h3 className="text-xl font-semibold mb-2 flex items-center">
                  <span className="text-3xl mr-2">{highlight.icon}</span>
                  <span>{highlight.title}</span>
                </h3>
                <p>{highlight.description}</p>
              </motion.div>
            ))}
          </div>
        </div>
      </section>

      {/* Instructors */}
      <section className="py-16">
        <div className="container mx-auto px-4">
          <h2 className="text-3xl font-bold mb-8 text-center">Meet Your Instructors</h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {instructors.map((instructor, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, scale: 0.9 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ delay: index * 0.1 }}
                className="bg-white rounded-lg shadow-md p-6 text-center"
              >
                <img
                  src={instructor.image}
                  alt={instructor.name}
                  className="w-24 h-24 rounded-full mx-auto mb-4"
                />
                <h3 className="text-xl font-semibold mb-1">{instructor.name}</h3>
                <p className="text-gray-600 mb-4">{instructor.role}</p>
                <p>{instructor.bio}</p>
              </motion.div>
            ))}
          </div>
        </div>
      </section>

      {/* Testimonials */}
      <section className={`${colors.secondary} text-white py-16`}>
        <div className="container mx-auto px-4">
          <h2 className="text-3xl font-bold mb-8 text-center">What Our Students Say</h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {testimonials.map((testimonial, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: index * 0.1 }}
                className="bg-white text-gray-800 rounded-lg shadow-md p-6"
              >
                <div className="flex items-center mb-4">
                  <img
                    src={testimonial.image}
                    alt={testimonial.name}
                    className="w-12 h-12 rounded-full"
                  />
                  <div className="ml-4">
                    <h3 className="text-lg font-semibold">{testimonial.name}</h3>
                    <p className="text-gray-600">{testimonial.role}</p>
                  </div>
                </div>
                <p className="italic">"{testimonial.comment}"</p>
              </motion.div>
            ))}
          </div>
        </div>
      </section>

      {/* Pricing */}
      <section className="py-16">
        <div className="container mx-auto px-4">
          <h2 className="text-3xl font-bold mb-8 text-center">Pricing</h2>
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className="max-w-md mx-auto bg-white rounded-lg shadow-md overflow-hidden"
          >
            <div className="p-6">
              <h3 className="text-2xl font-semibold mb-2">Full MERN Stack Course</h3>
              <p className="text-gray-600 mb-4">4-month intensive program</p>
              <p className="text-4xl font-bold mb-1">₹9,999</p>
              <p className="text-sm text-gray-500 mb-6">Limited time offer</p>
              <ul className="mb-6 space-y-2">
                {[
                  "Full course access",
                  "One-on-one mentorship",
                  "Group project experience",
                  "Certificate of completion",
                  "Job placement assistance"
                ].map((feature, index) => (
                  <li key={index} className="flex items-center">
                    <span className="text-green-500 mr-2">✓</span>
                    {feature}
                  </li>
                ))}
              </ul>
            </div>
            <div className="px-6 pb-6">
              <motion.button
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                className={`${colors.primary} text-white w-full py-3 rounded-full text-lg font-semibold hover:brightness-110 transition duration-300`}
              >
                Enroll Now
              </motion.button>
            </div>
          </motion.div>
        </div>
      </section>

      {/* FAQ */}
      <section className={`${colors.background} py-16`}>
        <div className="container mx-auto px-4">
          <h2 className="text-3xl font-bold mb-8 text-center">Frequently Asked Questions</h2>
          <div className="max-w-2xl mx-auto">
            {faqItems.map((item, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: index * 0.1 }}
                className="mb-4"
              >
                <button
                  onClick={() => setOpenFaqIndex(openFaqIndex === index ? null : index)}
                  className="flex justify-between items-center w-full text-left p-4 bg-white rounded-lg shadow-md"
                >
                  <span className="font-semibold">{item.question}</span>
                  <span>{openFaqIndex === index ? '−' : '+'}</span>
                </button>
                <AnimatePresence>
                  {openFaqIndex === index && (
                    <motion.div
                      initial={{ opacity: 0, height: 0 }}
                      animate={{ opacity: 1, height: 'auto' }}
                      exit={{ opacity: 0, height: 0 }}
                      className="bg-gray-100 p-4 rounded-b-lg"
                    >
                      <p>{item.answer}</p>
                    </motion.div>
                  )}
                </AnimatePresence>
              </motion.div>
            ))}
          </div>
        </div>
      </section>

      {/* Call to Action */}
      <section className={`${colors.primary} text-white py-20`}>
        <div className="container mx-auto text-center px-4">
          <h2 className="text-3xl font-bold mb-4">Ready to Start Your Journey?</h2>
          <p className="text-xl mb-8">Enroll now and become a MERN stack expert in just 4 months!</p>
          <motion.button
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            className={`${colors.accent} text-gray-900 px-8 py-3 rounded-full text-lg font-semibold hover:brightness-110 transition duration-300`}
          >
            Enroll Now for ₹9,999
          </motion.button>
        </div>
      </section>
    </div>
  );
};

export default CourseDetail;