import React from 'react';
import UserNavbar from '../../components/common/UserNavbar';
import { Navigate } from 'react-router-dom';
import UserFooter from '../../components/userFooter/UserFooter';
import { FaWhatsapp } from "react-icons/fa6";

const UserLayout = (props) => {
  // if (localStorage.getItem('adminInfo')) {
  //   return (
  //     <Navigate to={{ pathname: '/dashboard' }} />
  //   );
  // }

  const MessageButton = () => {
    const openMessageModal = () => {
      const phoneNumber = '919393991563';
      const whatsappUrl = `https://wa.me/${phoneNumber}`;
      window.open(whatsappUrl, "_blank");
    };

    return (
      <button
        className="fixed right-4 bottom-4 p-2 bg-[#f5e7e7] rounded-full shadow-lg hover:bg-[#f8bdbd] hover:scale-105 ease-in-out duration-300 focus:outline-none transition-transform transform-gpu"
        onClick={openMessageModal}
      >
        <FaWhatsapp size={32} color='#10b416' />
      </button>
    );
  };

  return (
    <React.Fragment>
      <UserNavbar />
      {props.children}

      {/* <UserFooter />

<MessageButton /> */}
      <UserFooter />
    </React.Fragment>
  )
}

export default UserLayout
