import React, { useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { RiMenu4Line } from "react-icons/ri";
import { IoClose } from "react-icons/io5";

const Sidebar = () => {
    const navigate = useNavigate();
    const path = useLocation();
    const [isSidebarOpen, setIsSidebarOpen] = useState(false)


    console.log(path?.pathname)

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen)
    }

    return (
        <>
            <div className="lg:contents hidden relative flex-shrink-0" aria-label="Sidebar">
                <div className="overflow-y-auto py-4 px-3 bg-cyan-400 h-screen">
                    <ul className="space-y-2">
                        <li>
                            <p className="text-lg font-semibold text-black dark:text-white">
                                {/* <img src={Logo} alt="logi" className='w-[150px] ml-3' /> */}
                                Logo
                            </p>
                        </li>

                        <li>
                            <div
                                onClick={() => navigate('/dashboard')}
                                className={`${path?.pathname === '/dashboard' && "bg-yellow-600"} flex items-center cursor-pointer p-2 text-base font-normal text-black rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-yellow-600 mt-6`}>
                                <span className="ml-3">
                                    Dashboard
                                </span>
                            </div>
                        </li>

                        <li>
                            <div
                                onClick={() => navigate('/settings')}
                                className={`${path?.pathname === '/settings' && "bg-yellow-600"} flex items-center cursor-pointer p-2 text-base font-normal text-black rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-yellow-600 mt-6`}>
                                <span className="ml-3">
                                    Settings
                                </span>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>

            <div className='block lg:hidden'>
                <button onClick={toggleSidebar} className='absolute ml-6 mt-[-50px] text-white'>
                    {isSidebarOpen ? <IoClose size={30} /> : <RiMenu4Line size={30} />}
                </button>
                <AnimatePresence>
                    {
                        isSidebarOpen &&
                        <motion.div
                            className='h-full w-[50%] absolute'
                            initial={{ opacity: 0, x: '-100%' }}
                            animate={{ opacity: 1, x: 0 }}
                            exit={{ opacity: 0, x: '-100%' }}
                            transition={{ duration: 0.3 }}
                        >
                            <div className="overflow-y-auto py-4 px-3 bg-gray-400 h-screen relative z-20">
                                <ul className="space-y-2">
                                    <li>
                                        <p className="text-lg font-semibold text-black dark:text-white">
                                            {/* <img src={Logo} alt="logi" className='w-[150px] ml-3' /> */}
                                            LOGO
                                        </p>
                                    </li>

                                    <li>
                                        <div
                                            onClick={() => {
                                                setIsSidebarOpen(false)
                                                navigate('/dashboard')
                                            }}
                                            className={`${path?.pathname === '/dashboard' && "bg-yellow-600"} flex items-center p-2 text-base font-normal text-black rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-yellow-600 mt-6`}>
                                            <span className="ml-3">
                                                Dashboard
                                            </span>
                                        </div>
                                    </li>

                                    <li>
                                        <div
                                            onClick={() => {
                                                setIsSidebarOpen(false)
                                                navigate('/settings')
                                            }}
                                            className={`${path?.pathname === '/settings' && "bg-yellow-600"} flex items-center p-2 text-base font-normal text-black rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-yellow-600 mt-6`}>
                                            <span className="ml-3">
                                                Settings
                                            </span>
                                        </div>
                                    </li>

                                </ul>
                            </div>
                        </motion.div>
                    }
                </AnimatePresence>
            </div>
        </>
    );
};

export default Sidebar;
