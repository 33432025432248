import React from 'react'
import styles from "../../../screens/users/home/Home.module.css";
import webDevelopment from "../../../assets/home/webDevelopment.svg";
import popularCourses from "../../../assets/home/popularCourses.svg";
import uiUxDesign from "../../../assets/home/uiUxDesign.svg";
import servicesBg from "../../../assets/home/servicesBg.jpg";
import appDevelopment from "../../../assets/home/appDevelopment.svg";


const HomeServices = () => {
  return (
    <div className={styles["services"]}>
        <div className='bg-black' >
          <h2 className="text-white lg:text-[30px] md:text-[30px]  text-[20px] text-center pt-10">
            {" "}
            <span className="text-qlithSky">{"<"}</span> Explore Our Services{" "}
            <span className="text-qlithSky"> {"/>"}</span>
          </h2>
          <div className={styles["services-grid"]}>
            <div className={styles["services-grid-card"]}>
              <div className={styles["services-content"]}>
                <h2 className={styles["services-heading"]}>Web Development</h2>
                <p>
                  We design and develop custom websites that are responsive,
                  fast, and tailored to meet your business needs, ensuring an
                  excellent user experience.
                </p>
              </div>
              <div className="flex justify-end items-end">
                <img
                  className="lg:w-[90%] w-full"
                  src={webDevelopment}
                  alt=""
                />
              </div>
            </div>
            <div className={styles["services-grid-card"]}>
              <div className={styles["services-content"]}>
                <h2 className={styles["services-heading"]}>App Development</h2>
                <p>
                  Creating high-performance mobile and web applications that are
                  scalable and optimized for both iOS and Android platforms,
                  delivering seamless experiences for users.
                </p>
              </div>
              <div className="flex justify-end items-end">
                <img
                  className="lg:w-[90%] w-full"
                  src={appDevelopment}
                  alt=""
                />
              </div>
            </div>
            <div className={styles["services-grid-card"]}>
              <div className={styles["services-content"]}>
                <h2 className={styles["services-heading"]}>Popular Courses</h2>
                <p>
                  Offering comprehensive courses in web development, app
                  development, and other key tech areas to help individuals and
                  businesses upskill in today’s digital landscape.
                </p>
              </div>
              <div className="flex justify-end items-end">
                <img
                  className="lg:w-[90%] w-full"
                  src={popularCourses}
                  alt=""
                />
              </div>
            </div>
            <div className={styles["services-grid-card"]}>
              <div className={styles["services-content"]}>
                <h2 className={styles["services-heading"]}>UI / UX Design</h2>
                <p>
                  Crafting intuitive and visually appealing interfaces that
                  enhance user engagement, blending creativity with
                  functionality for a seamless experience.
                </p>
              </div>
              <div className="flex justify-end items-end">
                <img className="lg:w-[90%] w-full" src={uiUxDesign} alt="" />
              </div>
            </div>
          </div>
          <div className="flex justify-center">
            <div className="flex justify-center items-center text-white hover:text-qlithSky mb-6 cursor-pointer w-52 border-b p-2 hover:border-qlithSky transition-all duration-700">
              <button>Explore More</button>
            </div>
          </div>
        </div>
      </div>
  )
}

export default HomeServices