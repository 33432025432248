import React, { useState, useEffect } from 'react'
import { motion, AnimatePresence } from 'framer-motion'
import { ChevronLeft, ChevronRight, Quote } from 'lucide-react'

const testimonials = [
  {
    id: 1,
    content: "This product has completely transformed my workflow. I can't imagine working without it now!",
    author: "Alice Johnson",
    role: "UX Designer",
    avatar:"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSijOi2pKsiEwN0u2kzA7Gyv65VqjR04SRwnw&s"
  },
  {
    id: 2,
    content: "The customer support team is incredibly responsive and helpful. They've gone above and beyond for us.",
    author: "Bob Smith",
    role: "Project Manager",
    avatar:"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRPPX-p3qDc6od7QCZALQiEb5OLAMnhQ8r8wQ&s"
  },
  {
    id: 3,
    content: "I've tried many similar products, but this one stands out for its intuitive design and powerful features.",
    author: "Charlie Brown",
    role: "Software Engineer",
    avatar:"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSnqund4TemOO_Wo_7XXODTQgDJhLD2gyrSMUCcVjO8xXCdwQvhU2sBdSKrOURgaqhc-AU&usqp=CAU"
  }
]

const useAutoSlide = (length, interval) => {
  const [current, setCurrent] = useState(0)

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrent((prevCurrent) => (prevCurrent + 1) % length)
    }, interval)

    return () => clearInterval(timer)
  }, [length, interval])

  return [current, setCurrent] 
}

export default function TestimonialSlider() {
  const [current, setCurrent] = useAutoSlide(testimonials.length, 5000)

  const next = () => setCurrent((current + 1) % testimonials.length)
  const prev = () => setCurrent((current - 1 + testimonials.length) % testimonials.length)

  return (
    <div className="relative w-full mx-auto">
      <div className="relative h-[400px] overflow-hidden rounded-xl shadow-2xl">
        <AnimatePresence initial={false} custom={current}>
          <motion.div
            key={current}
            custom={current}
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -50 }}
            transition={{ duration: 0.5 }}
            className={`absolute inset-0 p-8 pt-0 flex flex-col justify-center items-center text-center`}
          >
            <Quote className="w-12 h-12 mb-6 text-blue-300" />
            <blockquote className="text-xl italic text-gray-300 mb-6 relative">
              "{testimonials[current].content}"
            </blockquote>
            <div className="flex flex-col items-center">
              <div className="w-16 h-16 rounded-full bg-white shadow-inner mb-3 overflow-hidden border-2 border-black">
                <img src={testimonials[current]?.avatar} alt="" />
              </div>
              <p className="font-semibold text-gray-300">{testimonials[current].author}</p>
              <p className="text-gray-300">{testimonials[current].role}</p>
            </div>
          </motion.div>
        </AnimatePresence>
      </div>
      <div className="absolute left-0 right-0 bottom-6 flex justify-center space-x-4 ">
        <button
          onClick={prev}
          className="bg-white rounded-full p-2 shadow-md hover:bg-gray-100 transition-colors"
          aria-label="Previous testimonial"
        >
          <ChevronLeft className="w-6 h-6" />
        </button>
        <button
          onClick={next}
          className="bg-white rounded-full p-2 shadow-md hover:bg-gray-100 transition-colors"
          aria-label="Next testimonial"
        >
          <ChevronRight className="w-6 h-6" />
        </button>
      </div>
     
    </div>
  )
}
