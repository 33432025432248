import React from "react";
import {
  FaLinkedin,
  FaTwitter,
  FaArrowRight,
  FaLightbulb,
  FaRocket,
  FaUsers,
  FaQuoteLeft,
  FaAward,
  FaChartLine,
} from "react-icons/fa";

// Assuming you have these images in your project
import aboutUs from "../../../assets/about/aboutUsBanner.png";
import aboutUsBannerPhone from "../../../assets/about/aboutUsBannerPhone.png";

const teamMembers = [
  {
    name: "John Doe",
    role: "CEO",
    description:
      "John has over 15 years of experience in the tech industry, guiding Qlith Innovation to new heights with his visionary leadership.",
    img: "https://images.unsplash.com/photo-1560250097-0b93528c311a?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=256&q=80",
    linkedin: "#",
    twitter: "#",
  },
  {
    name: "Jane Smith",
    role: "CTO",
    description:
      "With a passion for cutting-edge technology, Jane drives the technical vision and oversees development at Qlith Innovation.",
    img: "https://images.unsplash.com/photo-1573496359142-b8d87734a5a2?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=256&q=80",
    linkedin: "#",
    twitter: "#",
  },
  {
    name: "Mike Johnson",
    role: "COO",
    description:
      "Mike ensures smooth operations and efficient processes, enabling Qlith to deliver top-notch services to our clients.",
    img: "https://images.unsplash.com/photo-1519085360753-af0119f7cbe7?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=256&q=80",
    linkedin: "#",
    twitter: "#",
  },
  {
    name: "Sarah Lee",
    role: "Head of Design",
    description:
      "Sarah brings creativity and user-centric design principles to every project, ensuring stunning and intuitive interfaces.",
    img: "https://images.unsplash.com/photo-1580489944761-15a19d654956?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=256&q=80",
    linkedin: "#",
    twitter: "#",
  },
];

const About = () => {
  
  return (
    <div className="bg-gradient-to-t from-[#303F9F] to-[#00102c]">
      {/* Hero Section */}
      <div
        className="bg-cover bg-fixed lg:flex hidden"
        style={{
          backgroundImage: `url('https://img.freepik.com/free-vector/background-realistic-abstract-technology-particle_23-2148431735.jpg')`,
        }}
      >
        <img src={aboutUs} alt="About Us Banner" className="w-full" />
      </div>
      <div
        className="bg-cover bg-fixed flex lg:hidden"
        style={{
          backgroundImage: `url('https://img.freepik.com/free-vector/background-realistic-abstract-technology-particle_23-2148431735.jpg')`,
        }}
      >
        <img
          src={aboutUsBannerPhone}
          alt="About Us Banner"
          className="w-full"
        />
      </div>

      {/* About the Company */}
      <section className="container mx-auto px-4 py-16">
        <div className="max-w-4xl mx-auto text-center">
          <h2 className="text-3xl md:text-4xl font-bold text-white mb-6">
            Our Story
          </h2>
          <p className="text-white text-lg leading-relaxed mb-8">
            Founded in 2024, Qlith Innovation Pvt Ltd has been at the forefront
            of digital transformation. We're dedicated to offering cutting-edge
            web and app development services while fostering a creative learning
            environment. Our mission is to provide tailored digital solutions to
            businesses and individuals, helping them achieve their goals through
            seamless web design, app development, and UI/UX services.
          </p>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <div className="bg-white p-6 rounded-lg shadow-md transition-transform duration-300 hover:scale-105">
              <FaLightbulb className="text-4xl text-blue-500 mb-4 mx-auto" />
              <h3 className="text-xl font-semibold mb-2">Innovation</h3>
              <p className="text-gray-600">
                Pushing boundaries with cutting-edge solutions
              </p>
            </div>
            <div className="bg-white p-6 rounded-lg shadow-md transition-transform duration-300 hover:scale-105">
              <FaRocket className="text-4xl text-green-500 mb-4 mx-auto" />
              <h3 className="text-xl font-semibold mb-2">Growth</h3>
              <p className="text-gray-600">
                Accelerating business success through technology
              </p>
            </div>
            <div className="bg-white p-6 rounded-lg shadow-md transition-transform duration-300 hover:scale-105">
              <FaUsers className="text-4xl text-purple-500 mb-4 mx-auto" />
              <h3 className="text-xl font-semibold mb-2">Collaboration</h3>
              <p className="text-gray-600">
                Building strong partnerships with our clients
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* Founder and Director Section */}
      <section className="bg-gray-100 py-16 bg-gradient-to-b from-black to-[#26B6CE]">
        <div className="container mx-auto px-4">
          <h2 className="text-3xl md:text-4xl font-bold text-white mb-12 text-center">
            Leadership
          </h2>
          <div className="grid md:grid-cols-3 gap-12">
            <div className="bg-white rounded-lg shadow-lg overflow-hidden">
              <img
                src={
                  "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQOeZjZWEr4oFmJhILQQgTy7-WUX9BmRrAAFw&s"
                }
                alt="CEO"
                className="w-full h-64 object-cover"
              />
              <div className="p-6">
                <h3 className="text-2xl font-bold text-gray-800 mb-2">
                  Dr. Emily Chen
                </h3>
                <p className="text-qlithSky font-semibold mb-4">
                  Founder & CEO
                </p>
                <p className="text-gray-600 mb-4">
                  Dr. Emily Chen, with her Ph.D. in Computer Science from MIT,
                  founded Qlith Innovation in 2024. Her visionary leadership and
                  technical expertise have positioned Qlith as a leader in
                  innovative tech solutions.
                </p>
              </div>
            </div>

            <div className="bg-white rounded-lg shadow-lg overflow-hidden">
              <img
                src={
                  "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQOeZjZWEr4oFmJhILQQgTy7-WUX9BmRrAAFw&s"
                }
                alt="CTO"
                className="w-full h-64 object-cover"
              />
              <div className="p-6">
                <h3 className="text-2xl font-bold text-gray-800 mb-2">
                  Saikh Mirsat
                </h3>
                <p className="text-qlithSky font-semibold mb-4">
                  Co-Founder & CTO
                </p>
                <p className="text-gray-600 mb-4">
                  Saikh Mirsat, with a passion for technology and innovation,
                  has driven the company’s technical vision since its inception.
                  As the CTO, he is responsible for ensuring that Qlith stays
                  ahead in tech advancements and delivers cutting-edge solutions
                  to its clients.
                </p>
              </div>
            </div>

            <div className="bg-white rounded-lg shadow-lg overflow-hidden">
              <img
                src={
                  "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQOeZjZWEr4oFmJhILQQgTy7-WUX9BmRrAAFw&s"
                }
                alt="COO"
                className="w-full h-64 object-cover"
              />
              <div className="p-6">
                <h3 className="text-2xl font-bold text-gray-800 mb-2">
                  Alex Rodriguez
                </h3>
                <p className="text-qlithSky font-semibold mb-4">
                  Chief Operating Officer (COO)
                </p>
                <p className="text-gray-600 mb-4">
                  Alex Rodriguez brings over 15 years of operational excellence
                  to Qlith Innovation. His strategic approach and deep
                  understanding of the tech landscape have been crucial in
                  scaling our operations and delivering consistent value to our
                  clients.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Team Section */}
      <section className="py-16 bg-black">
        <div className="container mx-auto px-4">
          <h2 className="text-3xl md:text-4xl font-bold text-white mb-12 text-center">
            Meet Our Team
          </h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8">
            {teamMembers.map((member, index) => (
              <div
                key={index}
                className="bg-white rounded-lg shadow-md overflow-hidden transition-transform duration-300 hover:scale-105"
              >
                <img
                  src={member.img}
                  alt={member.name}
                  className="w-full h-64 object-cover"
                />
                <div className="p-6">
                  <h3 className="text-xl font-bold text-gray-800 mb-2">
                    {member.name}
                  </h3>
                  <p className="text-qlithSky font-semibold mb-4">
                    {member.role}
                  </p>
                  <p className="text-gray-600 text-sm mb-4">
                    {member.description}
                  </p>
                  <div className="flex justify-center space-x-4">
                    <a
                      href={member.linkedin}
                      className="text-qlithNavy transition-colors duration-300"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FaLinkedin className="h-6 w-6" />
                    </a>
                    <a
                      href={member.twitter}
                      className="text-qlithSky transition-colors duration-300"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FaTwitter className="h-6 w-6" />
                    </a>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Our Approach */}
      {/* <section className="bg-gray-100 py-16">
        <div className="container mx-auto px-4">
          <h2 className="text-3xl md:text-4xl font-bold text-gray-800 mb-12 text-center">Our Approach</h2>
          <div className="grid md:grid-cols-2 gap-12">
            <div className="bg-white p-6 rounded-lg shadow-lg">
              <FaChartLine className="text-4xl text-blue-500 mb-4" />
              <h3 className="text-2xl font-semibold mb-4">Data-Driven Development</h3>
              <p className="text-gray-600">
                We leverage advanced analytics and user insights to inform our development process. This data-centric approach allows us to create solutions that not only meet but exceed user expectations, driving engagement and business growth.
              </p>
            </div>
            <div className="bg-white p-6 rounded-lg shadow-lg">
              <FaAward className="text-4xl text-green-500 mb-4" />
              <h3 className="text-2xl font-semibold mb-4">Quality Assurance</h3>
              <p className="text-gray-600">
                Our rigorous quality assurance process ensures that every product we deliver meets the highest standards of performance, security, and user experience. We employ automated testing, manual reviews, and user feedback loops to guarantee excellence.
              </p>
            </div>
          </div>
        </div>
      </section> */}

      <section className="container mx-auto px-4 py-16 bg-black">
        <div className="max-w-4xl mx-auto">
          <h2 className="text-3xl md:text-4xl font-bold text-white mb-8 text-center">
            Our Approach
          </h2>
          <div className="space-y-8">
            <div className="flex flex-col md:flex-row items-center gap-6">
              <div className="w-full md:w-1/2">
                <img
                  src="https://images.unsplash.com/photo-1522071820081-009f0129c71c?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=800&q=80"
                  alt="Collaborative Workspace"
                  className="rounded-lg shadow-md"
                />
              </div>
              <div className="w-full md:w-1/2">
                <h3 className="text-2xl font-semibold mb-4 text-[#26B6CE]">
                  Collaborative Development
                </h3>
                <p className="text-white">
                  We believe in working closely with our clients throughout the
                  development process. Our agile methodology ensures transparent
                  communication and regular updates, allowing for flexibility
                  and adaptability in our projects.
                </p>
              </div>
            </div>
            <div className="flex flex-col md:flex-row-reverse items-center gap-6">
              <div className="w-full md:w-1/2">
                <img
                  src="https://images.unsplash.com/photo-1551434678-e076c223a692?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=800&q=80"
                  alt="Innovative Solutions"
                  className="rounded-lg shadow-md"
                />
              </div>
              <div className="w-full md:w-1/2">
                <h3 className="text-2xl font-semibold mb-4 text-[#26B6CE]">
                  Innovative Solutions
                </h3>
                <p className="text-white">
                  At Qlith, we're always exploring cutting-edge technologies to
                  provide the most innovative solutions. Our team stays
                  up-to-date with the latest trends in web and app development
                  to ensure our clients receive state-of-the-art products.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Our Mission */}
      <section className="bg-qlithSky text-white py-16">
        <div className="container mx-auto px-4">
          <div className="max-w-3xl mx-auto text-center">
            <h2 className="text-3xl md:text-4xl font-bold mb-6">Our Mission</h2>
            <p className="text-lg leading-relaxed mb-8">
              At Qlith Innovation, our mission is to empower businesses and
              individuals with high-quality digital solutions and education. We
              strive to deliver exceptional services and learning experiences,
              transforming our clients' ideas into reality while fostering
              growth, creativity, and innovation.
            </p>
            <a
              href="#contact"
              className="inline-flex items-center bg-white text-qlithSky font-semibold py-3 px-6 rounded-full hover:bg-blue-100 transition-colors duration-300"
            >
              Get in Touch
              <FaArrowRight className="ml-2" />
            </a>
          </div>
        </div>
      </section>

      {/* Testimonials */}
      <section className="py-16 bg-[#0d262a]">
        <div className="container mx-auto px-4">
          <h2 className="text-3xl md:text-4xl font-bold text-white mb-12 text-center">
            What Our Clients Say
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {[
              {
                name: "Jane Doe",
                role: "CEO, Tech Corp",
                text: "Qlith Innovation transformed our business with their innovative web solutions. Their team's expertise and dedication are unmatched.",
              },
              {
                name: "John Smith",
                role: "Founder, StartUp Inc",
                text: "Working with Qlith was a game-changer for our startup. Their agile approach and cutting-edge tech skills helped us launch our MVP in record time.",
              },
              {
                name: "Emily Brown",
                role: "Marketing Director, Global Brands",
                text: "The UI/UX design Qlith delivered for our e-commerce platform significantly improved our conversion rates. Their attention to detail is impressive.",
              },
            ].map((testimonial, index) => (
              <div key={index} className="bg-white p-6 rounded-lg shadow-md">
                <FaQuoteLeft className="text-3xl text-blue-500 mb-4" />
                <p className="text-gray-600 mb-4">{testimonial.text}</p>
                <div className="flex items-center">
                  <img
                    src={`https://i.pravatar.cc/60?img=${index + 1}`}
                    alt="Client"
                    className="w-12 h-12 rounded-full mr-4"
                  />
                  <div>
                    <p className="font-semibold">{testimonial.name}</p>
                    <p className="text-sm text-gray-500">{testimonial.role}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
};

export default About;
