import React from 'react'
import servicesBanner from '../../../assets/services/servicesBanner.png';
import servicesBannerPhone from '../../../assets/services/servicesBannerPhone.png';

function Services() {
    return (
        <div className="bg-gradient-to-t from-[#303F9F] to-[#00102c]">
            {/* Hero Section */}
            <div
                className="bg-cover bg-fixed lg:flex hidden"
                style={{
                    backgroundImage: `url('https://png.pngtree.com/thumb_back/fh260/background/20240813/pngtree-rtf-file-with-a-magnifying-glass-and-digital-tools-surrounded-by-image_16158311.jpg')`,
                }}
            >
                <img src={servicesBanner} alt="About Us Banner" className="w-full" />
            </div>
            <div
                className="bg-cover bg-fixed flex lg:hidden"
                style={{
                    backgroundImage: `url('https://png.pngtree.com/thumb_back/fh260/background/20240813/pngtree-rtf-file-with-a-magnifying-glass-and-digital-tools-surrounded-by-image_16158311.jpg')`,
                }}
            >
                <img
                    src={servicesBannerPhone}
                    alt="About Us Banner"
                    className="w-full"
                />
            </div>
        </div>
    )
}

export default Services