import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

const AuthNavbar = () => {
    const navigate = useNavigate();
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    const handleLogout = () => {
        localStorage.clear();
        navigate('/');
    };

    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    return (
        <React.Fragment>
            <nav className="bg-yellow-500 p-4 text-white">
                <div className="container mx-auto flex justify-between items-center">
                    <div className="flex items-center">
                        <Link to="/" className="text-xl font-bold mr-4 lg:contents hidden">
                            My Admin Side
                        </Link>
                    </div>
                    <div className="flex items-center">
                        <button
                            id="dropdownDefaultButton"
                            onClick={toggleDropdown}
                            className="flex items-center"
                            type="button"
                        >
                            <img
                                src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSFbdiKrSNmO826RYdFFZNBWItbFgS7ZWmITgjhHwxIbA&s"
                                alt="profile-img"
                                className='w-10 h-10 rounded-full p-[1px] border bg-white' />
                            <svg
                                className={`w-2.5 h-2.5 ms-3 transform ${isDropdownOpen ? 'rotate-180' : ''}`}
                                aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 10 6"
                            >
                                <path
                                    stroke="currentColor"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="m1 1 4 4 4-4"
                                />
                            </svg>
                        </button>

                        {/* Dropdown menu */}
                        {isDropdownOpen && (
                            <div className="z-10 absolute mt-[150px] ml-[-100px] bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700">
                                <ul className="py-2 text-sm text-gray-700 dark:text-gray-200">
                                    <li>
                                        <Link to="/profile"
                                            onClick={toggleDropdown}
                                            className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">
                                            Profile
                                        </Link>
                                    </li>
                                    <li>
                                        <div onClick={() => {
                                            handleLogout();
                                            toggleDropdown();
                                        }} className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">
                                            Sign out
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        )}
                    </div>
                </div>
            </nav>
        </React.Fragment>
    );
};

export default AuthNavbar;
