import React, { useEffect, useState } from "react";
import styles from "./Home.module.css";
import Building from "../../../components/common/Building";
import HomeBanner from "../../../components/user/home/HomeBanner";
import HomeAbout from "../../../components/user/home/HomeAbout";
import HomeServices from "../../../components/user/home/HomeServices";
import HomeCourses from "../../../components/user/home/HomeCourses";
import HomeSuccessStories from "../../../components/user/home/HomeSuccessStories";
import HomeTestimonial from "../../../components/user/home/HomeTestimonial";

export default function Home() {
  const [showBuilding, setShowBuilding] = useState(false);

  const showBuildingModal = () => {
    const val = localStorage.getItem("showBuilding");
    if (!val) {
      setShowBuilding(true);
    }
  };

  useEffect(() => {
    showBuildingModal();
  });

  const hideBuildingModal = () => {
    localStorage.setItem("showBuilding", false);
    setShowBuilding(false);
  };

  return (
    <div>
      {showBuilding && <Building onclick={hideBuildingModal} />}
      <HomeBanner />
      <HomeAbout />
      <HomeServices />
      <HomeCourses />
      <HomeSuccessStories />
      <HomeTestimonial />
    </div>
  );
}
