import React from 'react'
import Comma_icon from "../../../assets/home/Comma_icon.svg";
import TestimonialSlider from './Testimonials';

const HomeTestimonial = () => {
  return (
    <div className="py-10 bg-gradient-to-b from-black to-qlithNavy lg:px-20 md:px-10 px-5 border-t border-gray-800">
    <h2 className="text-white text-[30px] mb-4 text-center">
      {" "}
      <span className="text-qlithSky">{"<"}</span> What Our Clients Say
      <span className="text-qlithSky"> {"/>"}</span>
    </h2>
    <div className="grid lg:grid-cols-2 grid-cols-1 lg:gap-0 gap-8">
      <div className="lg:p-20 p-10">
        <img
          src={Comma_icon}
          alt="Comma"
          className="absolute z-0 lg:-ml-20 -ml-10 lg:-mt-20 lg:w-[150px] w-[100px]"
        />
        <p className="tracking-wider text-lg text-white relative z-20 mt-8 lg:text-left text-justify">
          At Qlith Innovation, client satisfaction is our top priority. Hear
          from businesses and individuals who have experienced success
          through our innovative IT solutions and web development services.
          Our clients share their stories, highlighting the value we've
          brought to their projects, from transforming digital platforms to
          enhancing technical infrastructure.
        </p>
       <div className="flex justify-end">
       <img
          src={Comma_icon}
          alt="Comma"
          className="absolute z-0  lg:-mr-20 -mr-10 -mt-5 lg:w-[150px] w-[100px] rotate-180"
        />
       </div>
      </div>
      <div>
<TestimonialSlider />
      </div>
    </div>
  </div>
  )
}

export default HomeTestimonial