import React from 'react'
import library from "../../../assets/home/library.svg";

const HomeSuccessStories = () => {
  return (
    <div className="py-6 bg-gradient-to-b from-qlithNavy to-black lg:px-20 md:px-10 px-5">
        <div className="flex items-center justify-center flex-col lg:w-[80%]   m-auto">
          <h2 className="text-white md:text-[30px] text-lg mb-4">
            {" "}
            <span className="text-qlithSky">{"<"}</span> Our Success Stories
            <span className="text-qlithSky"> {"/>"}</span>
          </h2>
          <p className="text-white text-center lg:text:lg text-sm tracking-wider text-justify">
            Discover how Qlith Innovation Pvt Ltd has transformed businesses and
            helped clients achieve their goals. Our portfolio highlights a range
            of projects, from custom web development to cloud solutions,
            showcasing the measurable results and innovative strategies we
            implemented. Each case study outlines the challenges faced, our
            tailored solutions, and the success stories that followed.
          </p>
        </div>
        <div className="grid lg:grid-cols-2 grid-cols-1 mt-10 lg:gap-0 gap-8">
          <div className="flex items-center">
            <div className="border-2 border-[#198DA0] p-10">
              <h1 className="text-[#50d7ec] text-2xl">
                Library Management System (LMS)
              </h1>
              <p className="text-white mt-4 tracking-wider text-justify">
                A Library Management System (LMS) is a comprehensive software
                solution designed to simplify and automate the management of
                library operations. It offers a centralized platform to handle
                tasks such as cataloging books, tracking user activity, managing
                borrowing and returning of materials, and organizing digital and
                physical resources.
              </p>
            </div>
          </div>
          <div className="w-[100%] flex lg:justify-end justify-center">
            <img src={library} alt="image" className="lg:w-[70%]" />
          </div>
        </div>
        <div className="flex justify-center lg:-mt-0 mt-8 ">
          <div className="flex justify-center items-center text-white hover:text-qlithSky mb-6 cursor-pointer w-52 border-b p-2 hover:border-qlithSky transition-all duration-700">
            <button>Explore</button>
          </div>
        </div>
      </div>
  )
}

export default HomeSuccessStories