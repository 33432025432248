import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import styles from './Auth.module.css';
import Logo from '../../assets/Logo.svg';
import { LuEye } from "react-icons/lu";
import { LuEyeOff } from "react-icons/lu";
import Quote from '../../assets/Icons/Quote.svg';
import { GoogleLogin } from '@react-oauth/google';
import { jwtDecode } from "jwt-decode";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Spinner from '../../components/library/Spinner';
import axios from 'axios';

const Login = () => {
  const [showPass, setShowPass] = useState(false)
  const [loading, setLoading] = useState(false)
  const [oauthLoading, setOauthLoading] = useState(false)
  const [input, setInput] = useState({
    identifier: "",
    password: ""
  })

  const leftDiv = {
    clipPath: 'polygon(43% 0, 100% 24%, 100% 73%, 66% 100%, 25% 100%, 0 100%, 0 23%)'
  };
  const rightDiv = {
    clipPath: 'polygon(78% 0, 100% 9%, 100% 79%, 56% 100%, 0 79%, 0 19%, 34% 0)'
  };

  const handleChange = (key, val) => {
    setInput((prevInput) => ({
      ...prevInput,
      [key]: val
    }));
  };

  const handelLogin = async () => {
    setLoading(true)
    if (!input.identifier || !input.password) {
      toast.warning("Please all the fields");
      setLoading(false)
      return;
    }
    try {
      const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/users/login`, input);
      if (response?.data?.status) {
        toast.success(response?.data?.message)
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
    } finally {
      setLoading(false)
    }
  }

  const handelOauthLogin = async (email, name) => {
    setOauthLoading(true)
    try {
      const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/users/oauth-login`, { email: email, name: name });
      if (response?.status) {
        toast.success(response?.data?.message)
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
    } finally {
      setOauthLoading(false)
    }

  }

  return (
    <div className={styles['auth-bg']} >
      <div className='h-[100vh] w-full' >
        <div className={styles['right-div-wrapper']} >
          <div className={styles['shape-wrapper']} >
            <div className={styles['right-shape']} style={rightDiv}>
              {/* for mobil and tab */}
              <div className={styles['right-shape-mob']} style={leftDiv}>
              </div>
              <div className={styles['form']} >
                <div className='justify-center flex pb-2' >
                  <Link to="/" className='lg:w-16 md:w-16 w-10' >
                    <img src={Logo} alt="" />
                  </Link>
                </div>
                <h2 className={styles['heading']} >Login to your Account</h2>
                {/* <div className='py-2 my-3' >
                  {
                    oauthLoading ?
                      <div className={styles['oauth-loading']} >
                        <Spinner color='qlithSky' />
                      </div> : <GoogleLogin
                        onSuccess={credentialResponse => {
                          const decodedData = jwtDecode(credentialResponse?.credential);
                          console.log(decodedData?.email, decodedData?.name);
                          handelOauthLogin(decodedData?.email, decodedData?.name);
                        }}
                        onError={() => {
                          console.log('Login Failed');
                        }}
                      />
                  }

                </div> */}
                <div className='grid gap-2' >
                  <div>
                    <input onChange={(e) => handleChange('identifier', e.target.value)} className={styles['input']} type="text" placeholder='Email/ Phone/ Enrollment ID' />
                  </div>
                  <div>
                    <div className='border-b flex items-center rounded mt-4' >
                      <input onChange={(e) => handleChange('password', e.target.value)} className='rounded w-[92%] outline-none pt-2' type={showPass ? 'text' : 'password'} placeholder='Pa$$word' />
                      <button onClick={() => setShowPass(!showPass)} >{showPass ? <LuEye /> : <LuEyeOff />} </button>
                    </div>
                  </div>
                </div>
                <div className='flex justify-between text-xs mt-4' >
                  <div className='flex gap-1' >
                    <input type="checkbox" />
                    <p>Remember Me</p>
                  </div>
                  <div>
                    <p className='text-qlithSky' >Forgot Password?</p>
                  </div>
                </div>
                <button onClick={handelLogin} disabled={loading || oauthLoading} className={styles['btn']} >{loading ? <Spinner /> : 'Login'}</button>
              </div>
            </div>
          </div>
        </div>
        <div className={styles['left-div-wrapper']} >
          <div className={styles['left-shape']} style={leftDiv}>
            <div className='text-white ml-12' >
              <div className='text-[50px] font-bold leading-[45px]' >
                <div className='flex' ><span className='relative bottom-7 w-[50px]' ><img src={Quote} alt="" /></span> <p>Ready to unlock</p></div>
                <div className='flex ml-12'>new possibilities?<span className='relative  bottom-7 rotate-180' ><img src={Quote} alt="" /></span> </div>
              </div>
              <p className='ml-12 text-semibold text-[20px]' >Log in to innovate!</p>
            </div>
          </div>
        </div>

      </div>
    </div>
  )
}

export default Login
