import React from 'react'
import coreValuePic from "../../../assets/home/coreValuePic.png";
import coreValue2 from "../../../assets/home/coreValue2.png";
import { IoEyeOutline } from "react-icons/io5";
import { PiTarget } from "react-icons/pi";
import { GoThumbsup } from "react-icons/go";
import styles from "../../../screens/users/home/Home.module.css";

const HomeAbout = () => {
  return (
    <div className={styles["about"]}>
        <div>
          <div className="lg:h-[450px] lg:w-[450px] md:h-[350px] md:w-[350px] relative md:left-[30%] lg:left-0 h-[200px] w-full  rounded-xl">
            <img className="h-full w-full" src={coreValuePic} alt="" />
          </div>
          <div className="h-[250px] w-[250px] relative lg:left-[50%] lg:bottom-[30%] lg:-top-40 md:bottom-52 md:left-[10%]   hidden lg:flex md:flex rounded-xl">
            <img src={coreValue2} className="h-full rounded-xl" alt="" />
          </div>
        </div>
        <div>
          <p className="text-white text-[30px] mb-4">
            {" "}
            <span className="text-qlithSky">{"<"}</span> About{" "}
            <span className="text-qlithSky"> {"/>"}</span>
          </p>
          <h2 className="lg:text-[40px] md:text-[30px] text-[30px] text-white leading-[45px]">
            Empowering <span className="text-qlithSky">Success</span> Through
            Innovative IT Solutions
          </h2>
          <div className="flex mt-10">
            <div className={styles["values-bar"]}></div>
            <div className="text-white grid gap-5">
              <div className="flex gap-5">
                <div>
                  <button className={styles["values-icon"]}>
                    <IoEyeOutline />
                  </button>
                </div>
                <div>
                  <p className="text-xl">Our Vision</p>
                  <p>
                    To deliver innovative and reliable IT solutions that empower
                    businesses and individuals, fostering a culture of
                    continuous learning and excellence
                  </p>
                </div>
              </div>
              <div className="flex gap-5">
                <div>
                  <button className={styles["values-icon"]}>
                    <PiTarget />
                  </button>
                </div>
                <div>
                  <p>Mission</p>
                  <p>
                    To be a leading provider of IT solutions and educational
                    programs, recognized for our commitment to quality,
                    innovation, and customer success.
                  </p>
                </div>
              </div>
              <div className="flex gap-5">
                <div>
                  <button className={styles["values-icon"]}>
                    <GoThumbsup />
                  </button>
                </div>
                <div>
                  <p>Core Values</p>
                  <ol>
                    <li>Honesty and transparency in all interactions.</li>
                    <li>Embracing new ideas and technologies.</li>
                    <li>Valuing teamwork and partnerships.</li>
                    <li>Committing to the highest standards of quality.</li>
                    <li>
                      Prioritizing our clients' needs in all our solutions.
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  )
}

export default HomeAbout;