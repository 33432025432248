import React from 'react';
import MainNavbar from '../userNavbar/MainNavbar';

const UserNavbar = () => {
    return (
        <div className="sticky top-0 w-full z-50">
            <MainNavbar />
        </div>
    );
};

export default UserNavbar;